<template>
  <div class="mt-4 container-fluid">
    <div class="d-flex mb-4">
      <h3>{{ translations.page_title }}</h3>
    </div>
    <b-tabs content-class="mt-3">
      <b-tab :title="translations.tabs.account_statements" active>
        <statements-list/>
      </b-tab>
      <b-tab :title="translations.tabs.monthly_account_statements">
        <statement-summary-mergeList/>
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>
import translations from '@/translations';
import StatementsList from './list.vue';
import StatementSummaryMergeList from './statementSummaryMergeList.vue';

export default {
  components: {
    StatementsList,
    StatementSummaryMergeList,
  },
  name: 'FinanceStatementsPage',
  data() {
    return {
      translations: translations.finance.statements,
    };
  },
};
</script>
