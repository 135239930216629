<template>
  <div>
    <financial-list
      v-model="currentPage"
      :header-data="TABLE_HEADERS"
      :data="list"
      :events="TABLE_EVENTS"
      itemRefKey='id'
      :disabled="loading"
      :items-per-page="itemsPerPage"
      @page-changed="loadStatementSummaryMergeList"
      :total="total"
      :no-items-message="translations.empty_table"
      @row-download="exportStatementSummaryMerge"/>
  </div>
</template>

<script>
import translations from '@/translations';
import FinancialList, { associateHeaderDataItem } from '@/components/Financial/FinancialList.vue';
import { parseResponseError } from '@/http/parsers/parse_response';
import utils from '@/scripts/tools/utils';
import { formatDate, formatDatetime } from '@/helpers/finance';

export default {
  components: {
    FinancialList,
  },
  name: 'FinanceStatementsStatementSummaryMergeList',
  created() {
    this.TABLE_HEADERS = [
      associateHeaderDataItem(this.translations.id, 'id'),
      associateHeaderDataItem(this.translations.month, 'month'),
      associateHeaderDataItem(this.translations.created_at, 'created_at'),

    ];
    this.TABLE_EVENTS = {
      'row-download': {
        title: this.translations.action_download,
        icon: 'download',
      },
    };
  },
  data() {
    return {
      translations: translations.finance.statements.statement_summary_merge_list,
      list: [],
      loading: true,
      itemsPerPage: 10,
      currentPage: 1,
      total: 0,
    };
  },
  async beforeMount() {
    await this.loadStatementSummaryMergeList();
  },
  methods: {
    async loadStatementSummaryMergeList() {
      this.loading = true;

      try {
        const searchFilters = {
          offset: (this.currentPage - 1) * this.itemsPerPage,
          limit: this.itemsPerPage,
          order_by: 'id',
          order_descending: true,
        };

        const {
          statement_summary_merge: statementsSummaryMerge,
          total,
        } = await this.$store.dispatch('Financial/getStatementsSummaryMerge', searchFilters);

        this.list = statementsSummaryMerge.map(statementSummaryMerge => (
          {
            id: statementSummaryMerge.id,
            filename: statementSummaryMerge.filename,
            month: formatDate(statementSummaryMerge.file_date, 'MMMM YYYY'),
            created_at: formatDatetime(statementSummaryMerge.created_at),
          }
        ));
        this.total = total;
      } catch (_) {
        this.$noty.error(translations.fetch_list_error);
      }

      this.loading = false;
    },
    async exportStatementSummaryMerge(id, statementSummaryMerge) {
      try {
        const { data } = await this.$store.dispatch('Financial/downloadStatementSummaryMerge', { id });
        utils.downloadFile(data, statementSummaryMerge.filename);
      } catch (error) {
        let errorMsg = this.translations.file_generic_error;
        if (error.response && error.response.status === 404) {
          errorMsg = this.translations.file_not_available;
        }

        this.$noty.error(`${errorMsg}: ${parseResponseError(error)}`);
      }
    },
  },
};
</script>
