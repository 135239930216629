<template>
  <div class="d-flex align-items-center mr-4 w-100">
    <b-form-group :label="translations.filters.client_id">
    <b-form-select v-model="filter_set.client_id"
      :disabled="loading"
      :options="filter_options.clients"></b-form-select>
    </b-form-group>
    <b-form-group :label="translations.filters.created_at" class="flex-grow-1">
      <vue-ctk-date-time-picker format="YYYY-MM-DD"
        id="created-at-datepicker"
        :label="translations.filters.select_date"
        formatted="ll"
        range
        :error="!dateRangeValidator(filter_set.created_at)"
        only-date
        v-model="filter_set.created_at"/>
    </b-form-group>
    <b-form-group :label="translations.filters.start_end_date" class="flex-grow-1">
      <vue-ctk-date-time-picker format="YYYY-MM-DD"
        id="start-end-datepicker"
        :label="translations.filters.select_date"
        formatted="ll"
        range
        :error="!dateRangeValidator(filter_set.start_end_date)"
        only-date
        v-model="filter_set.start_end_date"/>
    </b-form-group>
    <b-button
      variant="primary"
      class="mt-2"
      @click="applyFilters"
      :disabled="!isFormValid()">
      <feather type="search"></feather>
    </b-button>
  </div>
</template>

<script>
import translations from '@/translations';

export default {
  name: 'StatementFilters',
  data() {
    return {
      loading: false,
      translations: translations.finance.statements,
      filter_set: {
        client_id: null,
        created_at: null,
        start_end_date: null,
      },
      filter_options: {
        clients: [
          { value: '', text: translations.finance.statements.filters.default_client_filter },
        ],
      },
    };
  },
  beforeMount() {
    this.loadClients();
  },
  methods: {
    recoverFiltersFromURL(filters) {
      this.filter_set = { ...filters };
    },
    applyFilters() {
      this.$emit('reset-page');
      this.$emit('apply-filters', this.filter_set);
    },
    async loadClients() {
      try {
        const { data } = await this.$store.dispatch('Financial/getFilters');
        const responseFilters = data
          .map(({ id: value, display_name: displayName }) => ({ value, text: `${displayName} (ID: ${value})` }));

        this.filter_options.clients = [ ...this.filter_options.clients, ...responseFilters ];
      } catch (_) {
        this.$noty.error(this.translations.errors.loading_filters);
      }
    },
    dateRangeValidator(field) {
      if (!field) {
        return true;
      }
      const { start, end } = field;
      return start && end;
    },
    isFormValid() {
      const validations = [
        () => this.dateRangeValidator(this.filter_set.created_at),
        () => this.dateRangeValidator(this.filter_set.start_end_date),
      ];

      return validations.reduce((prev, curr) => prev && curr(), true);
    },
  },
};
</script>
